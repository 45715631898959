<template>
  <LayoutGuest>
    <div class="container">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col">
          <div class="row mt-3">
            <div class="col-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4">
              <form action="#" class="form-signin needs-validation" novalidate>
                <h1
                  class="
                    h5
                    mb-3
                    font-weight-normal
                    text-center text-uppercase
                    mb-4
                  "
                >
                  Pengingat Password
                </h1>

                <div class="form-group">
                  <label for="email"
                    >Email: <span class="text-danger">*</span></label
                  >
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Masukkan alamat email..."
                    name="email"
                    required
                  />
                  <div class="valid-feedback">Valid.</div>
                  <div class="invalid-feedback">
                    Please fill out this field.
                  </div>
                </div>

                <button class="btn btn-main btn-block mt-4" type="submit">
                  RESET PASSWORD
                </button>
                <router-link
                  to="/login"
                  class="btn btn-link btn-block mt-4"
                  type="submit"
                  title="Daftar"
                  >Kembali</router-link
                >
              </form>
            </div>
          </div>
        </div>
        <!-- #main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </LayoutGuest>
</template>
<script>
import LayoutGuest from "../../components/guest/Layout.vue";

export default {
  components: {
    LayoutGuest,
  },
};
</script>